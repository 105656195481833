@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.home {
  height: 100vh;
  border-bottom: 2px solid #ffa351;
  overflow: hidden;
  scroll-behavior: smooth !important;

  .homeleft {
    width: 42%;
    height: 100vh;
    min-height: 500px;

    margin-left: 16%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;

    .brandcont {
      position: absolute;
      top: 2em;
      left: 2em;
      .brand {
        margin-left: auto;
        margin-right: auto;
        width: 4em;
        height: 4em;
        background-image: url("../img/logo.jpeg");
        border-radius: 100%;
        cursor: pointer;

        background-color: #cccccc;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 8px 10px -5px #000000;
      }
    }


    .insta-tag {
      padding: 1em;
      border: 1px solid #ffa351;
      font-weight: 600;
      display: flex;
      width: 80%;
      justify-content: center;
      align-items: center;
      .insta-home {
        // width: 20%;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1em;
      }
      
      .in {
        transition: all 0.3s;
        &:hover {
          transform: scale(1.15);
        }
      }
    }
    .hometitle {
      margin-top: 4em;

      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 90%;
      .home-title {
        img {
          width: 100%;
          margin: 0 0.1em;
          cursor: pointer;
        }
      }

      .tag {
        font-size: 1em;
        // font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: black;
        // font-family: cursive;
        margin-left: 2em;
        margin-top: -1em;
      }
    }

    .toright{
      position: absolute;
      bottom: 12%;

      .arrow,
      .arrow2 {
        width: 30px;
        height: 30px;
        color: #f8de7e;
        background-image: url("https://icon-library.com/images/dropdown-arrow-icon/dropdown-arrow-icon-29.jpg");
        background-size: contain;
      }

      .arrow2{
        margin-top: -15px;
      }

    }

    .bounce {
      animation: bounce 2s infinite;
    }
  }
  .homeright {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    width: 42%;
    height: 100vh;
    min-height: 500px;
    background-color: #f8de7e;
    display: flex;
    justify-content: center;
    align-items: center;

    .homeimg {
      width: 380px;
      height: 470px;
      background-color: white;
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      .car-0 {
        background-color: black;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-align: center;
        .slide-0 {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 0.5em;
          height: 470px;

          .car-img-1 {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
          }

          .car-img-1 {
            background-size: cover;
          }
        }
      }

      .carousel.carousel-slider .control-arrow {
        display: none;
      }
    }
  }
}

.openBtn {
  background: #f1f1f1;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
}

.openBtn:hover {
  background: #bbb;
}

.overlay {
  // transition: display 0.2s linear;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);

  .closebtn {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
    transition: all 0.3s;
    color: #ffa351;
    &:hover {
      color: white;
    }
    .cancel-btn {
      border-radius: 100%;
      font-size: 1.5em;
      color: white;
      background-color: #ffa351;
      transition: all 0.4s;
      &:hover {
        border: 2px solid #ffa351;
        background-color: white;
        color: #ffa351;
      }
    }
  }
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 60%;
  margin: auto;
  z-index: 1100;

  input[type="text"] {
    padding: 15px;
    outline: none;
    font-size: 1em;
    border: none;
    float: left;
    width: 90%;
    background: white;
    border-radius: 5px 0 0 5px;
  }

  .searchcont {
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid white;
    cursor: pointer;
    background-color: #ffa351;
    border-radius: 10px;
    transition: all 0.5s;
    // width: 10%;
    .x {
      position: absolute;
      right: 1em;
    }
    .searchbtn {
      height: 100%;
      float: left;
      margin: auto;
      color: white;
    }
    &:hover {
      border: 2px solid white;
    }
  }
  .sugs {
    height: 65vh;

    overflow-y: scroll;
    margin-top: 2em;
    background-color: #f8de7e;
    padding: 0em 1em;
    z-index: 10;

    .sugs-title {
      margin: 1em 0;
      border-bottom: 1px solid black;
      font-size: 1.2em;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: lightyellow;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track {
      background-color: #777;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home {
    .homeleft {
      margin-left: 0px;
      width: 50%;
    }

    .homeright {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .homeleft {
      width: 100%;
      .toright{
        margin-top: 2em;
      }
    }

    .homeright {
      width: 100%;
      margin-top: 100vh;
      .homeimg {
        height: 70vh;
        width: 70%;

        .car-0 {
          .slide-0 {
            height: 70vh;
            width: 100%;
          }
        }
      }
    }
  }

  .about {
    margin-top: 100vh;
  }

  .overlay-content {
    width: 80%;
    .sugs {
      height: 65vh;
    }
    input[type="text"] {
      width: 85%;
    }
  }
}

@media screen and (max-width: 400px) {
  .home {
    .homeleft {

      .insta-tag {
        width: 90%;
        font-size: 0.8em;
        display: flex;
      }

      .hometitle {
        margin-top: 4em;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90%;
        .home-title {
          img {
            width: 100%;
            margin: 0 0.1em;
            cursor: pointer;
          }
        }

        .tag {
          font-size: 0.8em;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          color: black;
          font-family: cursive;
          margin-left: 2em;
          margin-top: -1em;
        }
      }

 
    }

    .homeright {
      .homeimg {
        height: 70vh;
        width: 80%;

        .car-0 {
          .slide-0 {
            height: 70vh;
            width: 100%;
          }
        }

        .react-multiple-carousel__arrow {
          min-width: 30px;
          min-height: 30px;
        }
        .react-multiple-carousel__arrow::before {
          font-size: 15px;
        }
      }
    }
  }

  .overlay-content {
    width: 80%;

    input[type="text"] {
      width: 85%;
    }
    .searchcont {
      // width: 15%;

      .searchbtn {
        // padding: 0.1em 0.2em;
        height: 100%;
      }
    }

    .sugs {
      height: 65vh;
      overflow-y: scroll;
      margin-top: 2em;
      background-color: #f8de7e;
      padding: 0em 1em;

      .sugs-title {
        margin: 1em 0;
        border-bottom: 1px solid black;
        font-size: 1.2em;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: lightyellow;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-track {
        background-color: #777;
      }
    }
  }

  .overlay {
    .closebtn {
      top: 15px !important;
      right: 10px !important;
      .cancel-btn {
        font-size: 1em !important;
      }
    }
  }
}
