.footer {
  height: 40vh;
  display: flex;
  overflow: hidden;
  z-index: 2;
  bottom: 0;
  margin-top: auto;
  align-self: flex-end;

  .footer-middle {
    width: 40%;
    height: 40vh;
    border-right: 1px solid white;

    padding: 2em;
    display: flex;
    align-items: center;
    background-color: black;
    .foot-newtitle {
      color: white;
      font-size: 1em;
      margin: 1em 0;
    }
    .foot-newinput {
      margin: 1em 0;
      opacity: 1;
      input {
        width: 65%;
        padding: 0.8em;
        outline: none;
        border: none;
        border-radius: 10px;
        border: 3px solid #f8de7e;

        &:focus {
          border: 3px solid #ffa351;
        }
      }

      button {
        padding: 0.8em 1em;
        border-radius: 10px;
        border: 3px solid #ffa351;
        background-color: #ffa351;
        color: white;
        transition: all 0.5s;
        margin-left: 0.6em;
        font-weight: 600;

        &:hover {
          background-color: white;
          color: #ffa351;
          border: 3px solid #ffa351;
          font-weight: 600;

          cursor: pointer;
        }
      }
    }
  }
  .footer-left {
    height: 40vh;
    background-color: black;
    width: 30%;
    color: white;
    border-right: 1px solid white;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    padding-left: 2em;
    .footer-left-title {
      font-size: 2em;
      color: #ffa351;
      font-weight: 500;
      // margin-bottom: 0.5em;
      border-bottom: 1px solid white;
    }
    ul {
      margin-left: 1em;
      margin-top: -1.8em;
      li {
        margin: 0.5em 0;
        a{
          text-decoration: none;
          color: white;
          padding: 0.1em;
          transition: all 0.3s;
          overflow: hidden;
          visibility: visible;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-word;
          &:hover {
            color: #ffa351;
          }
        }
      }
    }
  }
  .footer-right {
    background-color: black;
    width: 30%;
    height: 40vh;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    justify-content: space-evenly;
    .footer-contact {
      padding: 0 2em;
      a {
        color: white;
        transition: all 0.3s;
        &:hover {
          color: #f8de7e;
        }
      }
    }
    .footer-socials {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: auto;
      margin-right: auto;
      .pin {
        .a {
          padding: 20px;
        }
      }
      img {
        transition: all 0.3s;
        height: 2em;
        width: 2em;
        object-fit: cover;
        margin: 0 0.8em;
        &:hover {
          transform: scale(1.15);
        }
      }
    }
  }
}

@media screen and (max-width: 1324px) {
  .footer {
    font-size: 1.2em !important;
    height: 105vh;
    min-height: 800px;
    flex-direction: column;
    .footer-left {
      width: 100%;
      border-bottom: 1px solid white;
      height: 35vh;
      min-height: 300px;

      align-items: center;
      padding-left: 0;
    }
    .footer-middle {
      .foot-news {
        width: 100%;
      }
      width: 100%;
      margin-left: auto;
      border-bottom: 1px solid white;
      height: 30vh;
      min-height: 200px;

      text-align: center;
    }
    .footer-right {
      width: 100%;
      height: 40vh;
      text-align: center;
      min-height: 300px;

    }
  }
}

@media screen and (max-width: 550px) {
  .footer {
    height: 90vh;
    min-height: 550px;
    font-size: .8em !important;

    flex-direction: column;
    .footer-left {
      width: 100%;
      border-bottom: 1px solid white;
      min-height: 180px;
      height: 30vh;
      align-items: center;
      padding-left: 0;
    }
    .footer-middle {
      zoom: 0.7;
      .foot-news {
        width: 100%;
      }
      width: 100%;
      margin-left: auto;
      border-bottom: 1px solid white;
      height: 30vh;
      text-align: center;
    }
    .footer-right {
      min-height: 250px;

      width: 100%;
      height: 40vh;
      text-align: center;
    }
  }
}
