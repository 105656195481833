.newsletter-section {
  height: 90vh;
  border-bottom: 3px solid #ffa351;
  width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  overflow: hidden;

  .news-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;

    .newsletter {
      width: 75%;
      height: 300px;
      background-color: #f8de7e;
      display: flex;
      margin-top: -3em;
      box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 16px 17px -5px #000000;

      .newimg {
        width: 35%;
        height: 100%;
        background-color: #f8de7e;
        background-image: url("../img/sakshi.png");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .newform {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 2em 1.5em;
        .newtitle {
          height: auto;
          padding: 0.5em 0;
          font-size: 1.5em;

          border-bottom: 2px solid #ffa351;
          text-align: center;
        }

        .newinput {
          input {
            width: 70%;
            padding: 0.8em;
            outline: none;
            border: none;
            border-radius: 10px;
            border: 3px solid #f8de7e;

            &:focus {
              border: 3px solid #ffa351;
            }
            // &::placeholder {
            //   font-family: "Alegreya", serif;
            // }
          }

          button {
            padding: 0.8em 1em;
            border-radius: 10px;
            border: 3px solid #ffa351;
            background-color: #ffa351;
            color: white;
            transition: all 0.5s;
            margin-left: 0.6em;
            font-weight: 600;

            &:hover {
              background-color: white;
              color: #ffa351;
              border: 3px solid #ffa351;
              font-weight: 600;

              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .section-title {
    margin-top: 0 !important;
  }
  .newsletter-section {
    padding: 4em 0;
    height: fit-content;
    .news-cont {
      // margin-top: 0;
      margin-top: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 50vh;
      .newsletter {
        .newimg {
          width: 45%;
        }

        .newform {
          width: 55%;
          .newtitle {
            font-size: 1.2em;
            text-align: center;
          }

          .newinput {
            margin-top: 0.5em;

            input {
              width: 90%;
            }

            button {
              transition: all 0.5s;
              font-weight: 600;
              width: 90%;
              margin-left: 0;
              margin-top: 0.5em;
              padding: 0.6em 0.8em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .newsletter-section {
    padding: 0;
    // height: 90vh ;
    height: fit-content;

    min-height: 550px;

    .news-cont {
      margin-top: -2em;

      .newsletter {
        // height: 80vh;
        height: fit-content;

        flex-direction: column;
        margin: 100px 0;

        zoom: 0.7;
        .newimg {

          width: 100%;
          min-height: 250px;
          height: 40vh;
          margin-top: 1em;
          background-position: 50% 10%;
        }

        .newform {
          margin-bottom: 1em;

          width: 100%;
          height: 200px;

          .newtitle {
            font-size: 1em;
            text-align: center;
          }

          .newinput {
            margin-top: 0.5em;

            input {
              width: 90%;
            }

            button {
              transition: all 0.5s;
              font-weight: 600;
              width: 90%;
              margin-left: 0;
              margin-top: 0.5em;
              padding: 0.6em 0.8em;
            }
          }
        }
      }
    }
  }
}
