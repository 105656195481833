a {
  text-decoration: none;
  color: black;
  .sug-cont {
    width: 100%;
    height: 200px;
    background-color: black;
    display: flex;
    margin-bottom: 1em;
    .sug-left {
      width: 40%;
      height: 200px;
display: flex;
align-items: center;
justify-content: center;
      .img {
        max-height:100%; max-width: 100%;
      }
    }
    .sug-right {
      width: 60%;
      height: 200px;
      background-color: white;
      padding: 1em;
      overflow: hidden;
      .sug-title {
        margin-bottom: 0.3em;
        border-bottom: 1px solid #ffa351;
        color: #ffa351;
        font-family: "Satisfy", cursive;
        text-overflow: ellipsis;
        font-size: 1.5em;
      }

      .sug-text {
        .sug-text-cont {
          height: 80px;
          overflow: hidden;
          margin-bottom: 0.4em;
          span {
            font-size: 0.7em;
            text-overflow: ellipsis;
            color: black;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            visibility: visible;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            word-break: break-word;
            overflow: hidden;
          }
        }
        .readmore{
          color:#ffa351;
          font-family: 700;
          font-size: .8em;
          font-family:Verdana, Geneva, Tahoma, sans-serif;
        }
      }
    }

    transition: all 0.2s;
    &:hover {
      box-shadow: 0px 8px 10px -5px #000000;
      transform: scale(1.01);
    }
  }
}


@media screen and (max-width: 500px) {

  a {
    text-decoration: none;
    color: black;
    .sug-cont {
      width: 100%;
      height: 130px;
      background-color: black;
      display: flex;
      margin-bottom: 1em;
      .sug-left {
        width: 40%;
        height: 130px;

      }
      .sug-right {
        width: 60%;
        height: 130px;
        background-color: white;
        padding: 1em;
        overflow: hidden;
        .sug-title {
          margin-bottom: 0.5em;
          border-bottom: 1px solid #ffa351;
          color: #ffa351;
          font-size: 1em;
          visibility: visible;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-word;
          overflow: hidden;
        }
  
        .sug-text {
            .sug-text-cont {
              height: 45px;
              overflow: hidden;
              margin-bottom: 0.4em;
              span {
                font-size: 0.5em;
                text-overflow: ellipsis;
                color: black;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                visibility: visible;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                word-break: break-word;
                overflow: hidden;
              }
            }
            .readmore{
              color:#ffa351;
              font-family: 700;
              font-size: .7em;
              font-family:Verdana, Geneva, Tahoma, sans-serif;
          }
    
        }
        .readmore{
          font-size: .7em;
        }
      }
  
      transition: all 0.2s;
      &:hover {
        box-shadow: 0px 8px 10px -5px #000000;
        transform: scale(1.01);
      }
    }
  }
  

}