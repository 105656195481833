.about {
  height: 100vh;
  min-height: fit-content;


  border-bottom: 2px solid #ffa351;
  display: flex;

  justify-content: center;
  align-items: center;
  // font-size: 1em;

  .card {
    cursor: pointer;
    background-image: url("../img/abt.jpeg");
    background-size: cover;
    height: 70vh;
    width: 70%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.01);
      box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 16px 17px -5px #000000;
      .card-content {
        transform: translateY(10%);
        transition-delay: 200ms;
        .card-title::after{
          transform: translateX(0);
          // width: calc(100% + 2em);
          transition-duration: .5s;
          transition-delay: .3s;

        }
      }
    }

    .card-content {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      padding: 1.5em;
      padding-bottom: 2em;
      background-color: rgba(0, 0, 0, 0.5);
      background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(20, 0, 0, 0.3),
        rgba(0, 0, 0, 1)
      );
      transform: translateY(55%);
      transition: all 0.5s;

      .card-title {
        position: relative;
        // width: max-content;
        padding: 0.3em 0;
        color: white;
        font-size: 2em;
        font-weight: 600;
        .subtitle {
          font-size: 0.5em;
        }
      }

      .card-title::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        width: calc(100% + 2em);
        left: -0.7em;
        transform: translateX(-100%);
        background: #f8de7e;
        transition: all 0.5s;

      }

      .card-body {
        color: white;
        padding: 1em 0em ;
        opacity: 0.9;
        width: 70%;
      }

      .btn {
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        color: black;
        background-color: #f8de7e;
        padding: 0.5em 0.8em;
        border-radius: 10px;
        transition: all 0.5s;
        margin-top: -5em;
        font-size: .8em;
      }
      .btn:hover {
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 958px) {
  .about {
    .card {
      background-position: 40% 0%;

      .card-content {
        transform: translateY(60%);
      }
      &:hover {
        transform: scale(1.02);
        box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 16px 17px -5px #000000;
        .card-content {
          transform: translateY(2%);
          transition-delay: 300ms;
        }
      }
    }
  }
}

@media screen and (max-width: 544px) {
  .about {
    .card {
      width: 80%;
      background-position: 40% 0%;
      font-size: 0.7em;

      .card-content {
        transform: translateY(60%);
      }
      &:hover {
        transform: scale(1.02);
        box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 16px 17px -5px #000000;
        .card-content {
          transform: translateY(5%);
          transition-delay: 300ms;
        }
      }
    }
  }
}
