// $background1:   #f8de7e;
$background1: black;

.card-container {
  width: 100%;
  height: auto;
  margin: 2em 0;
  // background-color: #ffa351;
}
.card-list {
  transition: all 0.3s;
  overflow-y: hidden;
  display: flex;
  padding: 3rem;
  width: 90%;
  // margin: auto 2em auto 2em;
  margin-left: auto;
  margin-right: auto;
  // margin: auto 2em;
  // background-color: #f8de7e;

  overflow-x: scroll;
  .card-deck {
    opacity: 1;

    background-size: cover;
    // background-size: contain;
    background-repeat: no-repeat;
    scroll-snap-align: start;
    background-position: center;
    box-shadow: -0.2rem 0 1.5rem #000;
    min-width: 350px;
    min-height: 350px;
    transition: 0.2s;
    clear: both;
    position: relative;
    cursor: pointer;
    background-color: $background1;
    border: none;
    padding: 1em;
    border-radius: 1em;
  }
  .card-deck:focus-within ~ .card-deck,
  .card-deck:hover ~ .card-deck {
    transform: translateX(140px);
  }

  .card-deck:hover {
    transform: translateY(-1.4rem);
    opacity: 1;
  }

  .card-deck:not(:first-child) {
    margin-left: -140px;
  }
}

.row {
  backface-visibility: hidden;
  transition: filter 200ms ease-in;
  &:hover {
    .project-card:not(:hover) {
      filter: brightness(0.9) saturate(0) contrast(1) blur(1.5px);
    }
  }
  .project-card {
    cursor: pointer;
    background-color: $background1;
    backface-visibility: hidden;
    border-radius: 1rem !important;
    transition: transform 200ms ease-in;
    &:hover {
      transform: scale(1.05) translateZ(0);
    }
  }
}

/* The Modal (background) */
.modal {
  transition: all 0.3s;

  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1300; /* Sit on top */
  padding: 100px 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  z-index: 1300; /* Sit on top */

  max-width: 700px;
  transition: opacity 0.5s linear;
}


#myModal{
  .like-cont{
    color:white;
    position:absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .like{
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    flex-direction: column;
    opacity: 0.01;
    transition: all 0.3s;

    .like-icon{
      font-size: 10em;
    }
    .likes{font-size: 2em;}

    &:hover{
    opacity: .9;
    }
  }
  }
}

// .like-cont:hover + #img{
//   opacity: .9;
// }
#img {
  z-index: 1500;
  height: 70vh;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1300; /* Sit on top */
  
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:hover{
    opacity: .9;
  }


}

#caption {
  margin: auto;
  display: block;
  position: relative;
  bottom: -93%;
  width: 10%;
  max-width: 700px;
  text-align: center;
  color: black;
  // color: black;
  font-size: 0.7em;
  background-color: #f8de7e;
  border-radius: 20px;
  padding: 5px;
  height: auto;
  z-index: 1; /* Sit on top */
  
  .like-icon{
    color: red;
  }
}
/* Add Animation - Zoom in the Modal */
// .modal-content, #caption {
//   animation-name: zoom;
//   animation-duration: 1s;
// }

// @keyframes zoom {
//   from {transform:scale(0)}
//   to {transform:scale(1)}
// }

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #ffa351;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #f8de7e;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 1170px) {
  #img {
    height: 70vh;
    width: 80%;
  }
  #caption {
    font-size: 1em;
    background-color: #f8de7e;
    border-radius: 20px;
    padding: 5px;
    height: auto;
  }
}

@media screen and (max-width: 370px) {
  #img {
    height: 70vh;
    width: 80%;
  }
  #caption {
    font-size: 0.8em;
    background-color: #f8de7e;
    border-radius: 20px;
    padding: 5px;
    height: auto;
  }
  .card-list {
    .card-deck {
      background-size: contain;

      box-shadow: -0.2rem 0 1.5rem #000;
      min-width: 250px;
      min-height: 250px;
      transition: 0.2s;
      clear: both;
      position: relative;
      cursor: pointer;
      background-color: $background1;
      border: none;
      padding: 1em;
      border-radius: 1em;
    }

    .card-deck:hover {
      transform: translateY(-0.4rem);
      opacity: 1;
    }

    .card-deck:focus-within ~ .card-deck,
    .card-deck:hover ~ .card-deck {
      transform: translateX(50px);
    }
    .card-deck:not(:first-child) {
      margin-left: -50px;
    }
  }
}
