.cat-page {

  .blog-loading {
    margin-left: 14%;
  }
  .cat-right {
    width: 86%;
    margin-left: 14%;
    min-height: 100vh;

    .title-cont {
      margin: 1em 1em 0 6em;
      .blog-title {
        text-align: center;
        color: #ffa351;
        font-size: 4em;
        border-bottom: 2px solid #ffa351;
        width: max-content;
        font-family: "Satisfy", cursive;
      
      }
    }

    .cat-post-cont {
      margin-left: auto;
      margin-right: auto;

      width: 80%;
      a {
        text-decoration: none;
        color: black;
        .sug-cont {
          margin-top: 2em;
          height: 300px;
          // border: 2px solid #ffa351;
          box-shadow: 0px 4px 8px -3px #000000;

          // border-radius: 25px;

          .sug-left {
            width: 40%;

            height: 100%;
            text-align: center;
            .img {
              // object-fit: contain;
              max-height:100%; max-width: 100%;
            }
          }
          .sug-right {
            width: 60%;
            // border-radius: 0 20px 20px 0;
            height: 100%;
            padding: 1em;
            overflow: hidden;
            .sug-title {
              margin-bottom: 0.3em;
              border-bottom: 1px solid #ffa351;
              color: #ffa351;
              font-size: 2em;
              font-family: "Satisfy", cursive;
              overflow: hidden;
              visibility: visible;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-word;

            }

            .sug-text {
              .sug-text-cont {
                height: 130px;
                margin-bottom: 0.6em;
                span {
                  visibility: visible;
                  display: -webkit-box;
                  -webkit-line-clamp: 6;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-align: left;
                  text-overflow: ellipsis;
                  width: 80%;
                  font-size: 0.7em;
                  margin: 1em 1em;
                  font-size: 0.7em;
                  color: black;
                  // font-family: Verdana, Geneva, Tahoma, sans-serif;
                }
              }
              .readmore{
                font-size: 1em;
              }
            }
          }

          transition: all 0.2s;
          &:hover {
            box-shadow: 0px 8px 10px -5px #000000;
            transform: scale(1.01);
          }
        }
      }
    }
  }

  .footer {
    margin-top: 6em;
  }
}

@media screen and (max-width: 1150px) {
  .cat-page {
    .blog-loading {
      font-size: 3em;
      margin-left: 0;
    }
    .footer {
      margin-top: 6em;
      font-size: 1em !important;
    }
    .cat-right {
      margin-left: 0;
      width: 100%;

      .cat-post-cont {
        a {
          .sug-cont {
            // height: 150px;
            .sug-left {
              display: flex;
              align-items: center;
              justify-content: center;
              .img {
              max-height:100%; max-width: 100%;


              }
            }
          }}

          }



      .title-cont {
        margin: 2em 0 2em 4em;
      }
      .sug-title {
        font-size: 1em;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cat-page {
    .cat-post-cont {
      a {
        // .sug-cont {
        // }
        font-size: 0.7em;
      }
    }
  }
}
@media screen and (max-width: 530px) {
  .cat-page {
    .blog-loading {
      font-size: 1.4em;
      margin-left: 0;
    }
    .cat-right {
      margin-left: 0;
      width: 100%;

      .title-cont {
        margin: 2em 0 0 4em;
        font-size: .5em;
      }

      .cat-post-cont {
        a {
          .sug-cont {
            height: 150px;
            .sug-left {
              display: flex;
              align-items: center;
              justify-content: center;
              .img {
              max-height:100%; max-width: 100%;


              }
            }
            .sug-right {
              .sug-title {
                font-size: 1.5em;
              }

            .sug-text {
              .sug-text-cont {
                height: 50px;
                margin-bottom: 0.6em;
                span {
                  visibility: visible;
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-align: left;
                  text-overflow: ellipsis;
                  width: 80%;
                  font-size: 0.7em;
                  margin: 1em 1em;
                  font-size: 0.7em;
                  color: black;
                  // font-family: Verdana, Geneva, Tahoma, sans-serif;
                }
              }
              .readmore{
                font-size: 1em;
                margin: 1.5em 1em;
              }
            }
            }
          }
        }
      }

      .footer {
        font-size: 0.7em !important;
      }
    }
  }
}
