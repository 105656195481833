.page {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f8de7e ;
  width: 100%;
  height: 100vh;
  .cont {
    width: 60%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 100px;
      width: 100px;
      margin: 1.5em;
      border-radius: 100%;
      box-shadow: 0px 8px 10px -5px #000000;

    }

    .tag {
      margin-left: 8em;
      margin-top: -1em;
    }

    .sf-cont {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2em;

      .sf {
        width: 25%;
        margin-bottom: -2em;
      }
    }

    .load-cont {
      width: 100%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    .cont {
      width: 100%;
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: none;
      align-items: center;
      text-align: center;

      .logo {
        height: 180px;
        width: 180px;
        margin: 1.5em;
        border-radius: 100%;
      box-shadow: 0px 10px 10px -5px #000000;


      }
      .title-cont {
        height: 20vh;
        width: 100%;
        .title {
        width: 80%;

        }
      }
      .tag {
        font-size: 1.2em;
        margin-left: 3em;
        // margin-top: -5em;
      }
      .sf-cont {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
  
        .sf {
          width: 35%;
          margin-bottom: -2em;
        }
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .page {
    .cont {
      width: 100%;
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: none;
      align-items: center;
      text-align: center;

      .logo {
        height: 80px;
        width: 80px;
        margin: 1.5em;
        border-radius: 100%;
        box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 10px 10px -5px #000000;

      }
      .title-cont {
        height: 20vh;
        .title {
          width: 80%;
        }
      }
      .tag {
        font-size: 0.7em;
        margin-left: 3em;
        // margin-top: -5em;
      }
      .sf-cont {
        .sf {
          margin-bottom: -2em;
        }
      }
    }
  }
}
