.blog {
  border-bottom: 2px solid #ffa351;
  overflow: hidden;
  font-size: 1em;
  .blog-cont {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .blog-sec {
      border-radius: 15px;
      border: 4px solid #ffa351;
      background-color: #ffa351;
      color: white;
      padding: 1.5em 2em;
      margin: 2em 0;
      transition: all 0.3s;
      box-shadow: inset 0 0 0 1px white;

      .blog-sec-title {
        margin-left: auto;
        margin-right: auto;
        padding: 0.2em 0.5em;
        margin-bottom: 0.5em;
        font-weight: 700;
        font-family: "Satisfy", cursive;
        font-size: 2em;
        border-bottom: 2px solid white;
        width: max-content;
        display: flex;
        align-items: center;

        .icon {
          font-size: 1.1em;
          margin-left: 0.2em;
        }
      }
      .blog-sec-tag {
        font-size: 1.2em;
        font-weight: 500;
      }

      &:hover {
        transform: scale(1.02);
        border: 4px solid #f8de7e;
        .blog-sec-title {
          border-bottom: 2px solid #ffa351;
        }

        background-color: white;
        color: #ffa351;
      }
    }

    .options {
      height: auto;
      width: 90%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      .cat-title {
        border-bottom: 1px solid black;
        padding: 0.2em 0;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
      }

      .cat-content {
        padding: 0.7em;
      }

      .cat-item {
        padding: 0.2em 0.8em;
        color: white;
        background-color: #ffa351;
        border-radius: 20px;
        margin: 0.2em;
        display: inline-block;
        cursor: pointer;
        border: 2px solid #ffa351;
        transition: all 0.5s;
        &:hover {
          background-color: white;
          border: 2px solid #ffa351;

          color: #ffa351;
        }
      }

      .readers,
      .categories {
        margin: 2em;
        border-radius: 20px;
        padding: 1em;
        background-color: #f8de7e;
        font-size: 0.9em;
      }
      .categories {
        width: 40%;
      }
      .readers {
        width: 60%;
      }
    }

    // .blogtitle {
    //   margin: 0.8em 0;
    //   width: auto;
    //   display: flex;
    //   justify-content: center;
    //   border-bottom: 4px solid red;
    //   font-size: 2em;
    //   font-weight: 700;
    //   font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;

    //   text-shadow: 1px 1px black;

    //   color: #ffa351;
    // }

    .car-1 {
      background-color: #f8de7e;
      margin-top: 2em;
      width: 100%;
      padding: 1em;
      .slide-1 {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        height: 520px;
        width: 90%;

        .link {
          height: auto;
          background-color: white;
          border-radius: 10px;
          cursor: pointer;
          padding: 1em;
          width: 80%;
          transition: all 0.5s;

          &:hover {
            transform: scale(1.05) !important;
            box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 16px 17px -5px #000000;
            border-radius: 20px;
          }

          .post-cont {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            // font-family: "Alegreya", serif !important;

            .post-title {
              padding: 0.1em 0.6em;
              color: #ffa351;
              width: 80%;
              font-size: 1.4em;
              border-bottom: 1px solid #ffa351;
              font-family: "Satisfy", cursive;
              overflow: hidden;
              visibility: visible;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-word;

            }
            .post-img {
              height: 18vh;
              min-height: 80px;
              margin: 1em 0 .5em;
              width: auto;
              // box-shadow: inset 10px 10px 10px rgba(1, 0.1, 0.1, 0.2);
              // box-shadow: 2px 2px 2px 2px #000000;
              box-shadow: 0px 12px 16px -5px #000000;
            }
            .post-desc {
              visibility: visible;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              word-break: break-word;
              overflow: hidden;
              text-align: left;
              width: 80%;
              font-size: 0.7em;
              margin: .8em;

            }

            .post-btn {
              padding: 0.4em 0.8em;
              font-size: 0.7em;
              margin-top: 0.9em;
              color: white;
              background-color: #ffa351;
              border-radius: 10px;
              border: 2px solid #ffa351;
              transition: all 0.5s;

              &:hover {
                color: #ffa351;
                background-color: white;
                border: 2px solid #ffa351;
              }
            }
          }
        }
      }
    }

    .car-reader {
      background-color: black;
      margin: 2em 0;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      cursor: pointer;
      text-align: center;

      .slide-abt {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 0.5em 0em 0.5em;
        height: 300px;

        .car-img-1 {
          width: 100%;
          height: 100%;
          margin: 1em 0;
          background-position: center;
          background-repeat: no-repeat;
        }

        .car-img-1 {
          background-size: contain;
        }
      }
      .carousel.carousel-slider .control-arrow {
        display: none;
      }

      .react-multiple-carousel__arrow {
        min-width: 35px;
        min-height: 35px;
      }
      .react-multiple-carousel__arrow::before {
        font-size: 15px;
      }
    }
  }
}
.socials {
  margin: 0.8em 0.2em 0.2em;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  .icon-holder-post {
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      transition: all 0.5s;
      width: 1.5em;
      &:hover {
        border-radius: 100%;
        box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 5px 10px -5px #000000;
        background-color: #f8de7e;
      }
    }
  }
}

// .carousel .control-arrow,
// .carousel.carousel-slider .control-arrow {
//   background-color: #ffa351 !important;
//   top: 50% !important;
//   transform: translateY(-50%);
//   opacity: 1 !important;
//   border: 2px solid #ffa351 !important;
//   &:hover {
//     // border: 1px solid white !important;
//     color: #ffa351 !important;
//     opacity: 0.7 !important;
//   }
// }

// .carousel .control-next.control-arrow:before {
//   &:hover {
//     border-left: 8px solid #ffa351 !important;
//   }
// }

// .carousel.carousel-slider .control-arrow {
//   top: 50%;
//   height: 70px;
//   margin: 0 1em;
// }

// .carousel .control-dots .dot {
//   width: 16px !important;
//   height: 16px !important;
//   // background-color: #ffa351  !important;
//   border: 3px solid #ffa351;
// }
// .carousel .carousel-status {
//   display: none !important;
// }

@media screen and (max-width: 920px) {
  .blog {
    .blog-cont {
      .car-1 {
        .slide-1 {
          height: 500px;
          // width: 330px;
          width: 90%;
          .link {
            .post-cont {
              height: 400px;

              .post-img {
                height: 10vh;
              }
              .post-desc {
                -webkit-line-clamp: 5;
              }
            }
          }
        }
      }

      .blog-sec {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.7em;
      }

      .options {
        flex-direction: column;
        width: 90%;

        .categories {
          width: 70%;
        }
        .readers {
          width: 90%;
        }
      }

      .react-multi-carousel-list.car-reader {
        width: 90%;
        text-align: center;
        // display: inline-block;
        .slide-abt {
          width: 100%;

          .car-img-1 {
            width: 100%;
            height: 100%;
            margin: 1em 0;
            box-shadow: 0px 8px 10px -5px #000000;

          }
        }
        .carousel.carousel-slider .control-arrow {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .blog {
    .blog-cont {
      .car-1 {
        .slide-1 {
          height: 350px;
          margin-left: 1.6em;
          // width: 330px;
          width: 80%;

          font-size: 0.7em;
          .link {
            .post-cont {
              height: 300px;

              .post-img {
                height: 15vh;
                // width: 60%;
              box-shadow: 0px 8px 10px -5px #000000;

              }
              .post-desc {
                -webkit-line-clamp: 4;
              }
            }
          }
          .react-multiple-carousel__arrow {
            min-width: 30px;
            min-height: 30px;
          }
          .react-multiple-carousel__arrow::before {
            font-size: 15px;
          }
        }

      }

      .blog-sec {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.7em;
      }

      .options {
        flex-direction: column;
        width: 90%;

        .categories {
          width: 70%;
        }
        .readers {
          width: 95%;
        }
      }

      .react-multi-carousel-list.car-reader {
        width: 90%;
        text-align: center;
        // display: inline-block;
        .slide-abt {
          width: 100%;

          .car-img-1 {
            width: 100%;
            height: 100%;
            margin: 1em 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }

        }
        .react-multiple-carousel__arrow {
          min-width: 25px;
          min-height: 25px;
        }
        .react-multiple-carousel__arrow::before {
          font-size: 10px;
        }
      }
    }
  }
}
