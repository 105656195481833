.blog-cont {
  .blog-loading {
    font-size: 1.5em;
  }
  .blog-right {
    width: 86%;
    margin-left: 14%;
    min-height: 100vh;

    .scroll-main {
      background-color: black;
      height: 6px;
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      .scroll-in {
        height: 6px;
        background-color: #ffa351;
      }
    }
    .title-cont {
      // margin-left: 10em;
      padding: 0 7em;
      .blog-title {
        color: #ffa351;
        margin: .3em 0;
        word-break: break-word;
        
        font-size: 3em;
        border-bottom: 2px solid #ffa351;
        // width: max-content;
        font-family: "Satisfy", cursive;

      }
      .sub-cont {
        margin: 0.5em 0;
        display: flex;
        .date {
          color: black;
          padding-right: 0.5em;
          border-right: 1px solid black;
        }
        .cats {
          display: flex;
          .cat {
            padding: 0 0.5em;
            border-right: 1px solid black;
          }
        }
      }
    }

    .blog-img {
      background-color: black;
      margin: 2em 0;
      height: 60vh;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0px 10px 12px -5px #000000;


      cursor: pointer;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
    .blog-cont1 {
      padding: 0 10em 4em;
      display: flex;
      flex-direction: column;
      span {
        color: black;
        font-size: 1.2em;
        // font-family: "Alegreya", serif;
        font-family: 'Quicksand', sans-serif;

      }
    }
  }
}

.blog-loading {
  font-size: 2em;
  margin-top: 1em;
  text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1150px) {

  .blog-cont {
    .blog-right {
      margin-left: 0;

      width: 100%;
      .title-cont {
        .blog-title {
          font-size: 3em;
          margin: .8em 0;
        }
        padding: 0 3em;

      }
      .blog-cont1 {
   
        padding: 0 4em 4em;
        font-size: 1em;
        span {
          color: black;
        }
      }

      .scroll-main {
        height: 5px;
        .scroll-in {
          height: 5px;
        }
      }

      .blog-img {
        height: 35vh;
        width: 70%;
      }

      .footer {
        font-size: 1em !important;
      }
    }
  }
}

@media screen and (max-width: 550px) {


  .blog-cont {
    .abt-left {
      display: none;
    }
    
    .blog-right {
      margin-left: 0;
      
      width: 100%;
      
      .scroll-main {
        height: 2px;
        .scroll-in {
          height: 2px;
        }
      }
      .title-cont {
        // margin: 0.8em 0.8em 0.8em 3em;
        font-size: .6em !important;

        .blog-title{
          font-size: 4em;
          margin: .4em 0;
        }
          padding: 0 2em;
      }
      
      .blog-img {
        height: 35vh;
        width: 70%;
      }
      .blog-cont1 {
        padding: 0 2em 2em;
        font-size: 0.8em;
        span {
          color: black;
        }
      }

          .footer{
            font-size: 0.6em !important;
        }
    }
  }
}


@media screen and (max-width: 300px) {
body{
  zoom:.8;
  .title-cont {
    padding: 0 2em;
  }
}

}