

.adm-posts {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 1em auto 1em auto;

  width: 60%;

  .adm-post {
    background-color: bisque;
    // width: max-content;
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin: 10px 0;
    border-radius: 10px;
    padding: 0.5em 0.8em;
    .icn-hol {
      display: flex;
      .icon {
        margin: 0px 5px;
        background-color: burlywood;
        cursor: pointer;
      }
    }
  }
}
.post-form-cont {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .cat-item {
    padding: 0.2em 0.8em;
    color: white;
    background-color: #ffa351;
    border-radius: 20px;
    margin: 0.2em;
    display: inline-flex;
    cursor: pointer;
    border: 2px solid #ffa351;
    transition: all 0.5s;
    &:hover {
      background-color: white;
      border: 2px solid #ffa351;

      color: #ffa351;
    }
  }

  .post-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .new-btn {
      padding: 0.3em 0.5em;
      margin: 1em 0;
      background-color: burlywood;
      cursor: pointer;
      border-radius: 8px;
    }
    form {
      width: 60%;
    }

    textarea {
      padding: 0.5em;
      border-radius: 10px;
      margin: 1em 0;
      width: 100%;
    }
    button {
      padding: 0.5em;
      width: 50%;
      cursor: pointer;
    }
  }
  input {
    padding: 0.5em;
    border-radius: 10px;
    margin: 1em 0;
    width: 50%;
  }
}

.vid-form-cont {
  input {
    padding: 0.5em;
    border-radius: 10px;
    margin: 1em 0;
    width: 100%;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;

  button {
    padding: 0.5em;
    width: 50%;
    cursor: pointer;
  }
  .icon {
    margin: 0px 5px;
    background-color: burlywood;
    cursor: pointer;
  }
}
