.Blog-section {
  .blog-loading {
    margin-left: 14%;
  }

  .blog-sec-right {
    width: 86%;
    height: auto;
    margin-left: 14%;
    min-height: 100vh;
    background-color: white;

    .cat {
      height: auto;
      padding-top: 2em;
      margin: 0 1em ;
    }
    .cat-name {
      font-size: 2em;
      color: #ffa351;
      border-bottom: 1px solid #ffa351;
      width: max-content;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-family: "Satisfy", cursive;


    }


    .card-cont {
      height: 200px;
    }
  }
}

.swiper-slide {
  background-color: #f8de7e;
  width: 300px;
  padding: 1em;
  cursor: pointer;
  text-align: center;

  .sw-title {
    text-align: center;
    border-bottom: 1px solid black;
    margin-left: auto;
    margin-right: auto;
    font-family: "Satisfy", cursive;
    margin: .3em .2em;
    overflow: hidden;
    visibility: visible;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
  .sw-img {
    min-height: 20vh;
    height: 60px;
    width: auto;
    margin-top: 1em ;
    box-shadow: 0px 12px 16px -5px #000000;

  }
  .sw-text {
    font-size: 0.7em;
    // height: 80px;
    margin: 1.5em 1em;
    // padding: .7em;

    visibility: visible;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
  .sw-text1 {
    text-align: center;
  }

  .sw-btn {
    text-align: center;
    margin-top: 0.5em;
    background-color: #ffa351;
    color: white;
    border-radius: 5px;
    padding: 0.2em 0;
    transition: all 0.3s;
    &:hover {
      color: #ffa351;
      background-color: white;
    }
  }
}

.swiper-slide {
  box-shadow: 0px 8px 10px -5px #000000;
}


.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #ffa351;
}

:root {
  --swiper-theme-color: #ffa351 !important;
}

@media screen and (max-width: 1190px) {
  .Blog-section {
    .footer {
      font-size: 1.5em !important;
    }

    .blog-loading {
      margin-left: 0;
    }

    .abt-left {
      display: none;
    }
    font-size: 0.7em;
    .swiper-slide {
      cursor: pointer;
      .sw-title {
        font-size: 1.5em;
      }
      .sw-img {

        min-height: 50px;
        height: 140px;
        width: auto;
        margin: 1em auto;
        box-shadow: 0px 8px 15px -5px #000000;
      }
      .sw-text {
        font-size: 1em;
        margin: 1em auto;
        width: 70%;
        -webkit-line-clamp: 5;

      }

      .sw-btn {
        margin: 0.5em auto;
        width: 70%;
      }
    }

    .blog-sec-right {
      margin-left: 0;
      width: 100%;
    }
  }


}

// @media screen and (max-width: 550px) {
//   .container{
//     height: 60vh;

//   }
// }

@media screen and (max-width: 550px) {
  .Blog-section {
    .blog-sec-right {
      width: 100%;
      margin-left: 0;
      .cat {
        align-items: center;
        // height: 50vh;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 2em;
 
      }
    }
    .abt-left {
      display: none;
    }
    font-size: 0.7em;
    .swiper-slide {
      width: 200px;
      cursor: pointer;
      .sw-title {
        font-size: 1.5em;
      }
      .sw-img {
        // min-height: 10vh;
        width: 70%;
        margin: 1em auto;
        height: 140px;
        width: auto;


      }
      .sw-text {
        font-size: 1em;
        // height: 80px;
        margin: 2em auto;
        width: 70%;

        -webkit-line-clamp: 5;

      }

      .sw-btn {
        margin: 0.5em auto;
        width: 70%;
      }
    }

    .footer {
      font-size: 1em !important;
    }
  }
}

@media screen and (max-width: 380px) {
  .Blog-section {
    .blog-sec-right {
      width: 100%;
      margin-left: 0;
      .cat {
        align-items: center;
        height:auto;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .abt-left {
      display: none;
    }
    font-size: 0.7em;
    .swiper-slide {
      width: 200px;
      cursor: pointer;
      .sw-title {
        font-size: 1.5em;
        width: 80%;
        margin-left: auto;
        margin-right: auto;


      }
      .sw-img {
        min-height: 50px;
        height: 120px;

        width: auto;
        margin: 1em auto;
    box-shadow: 0px 8px 15px -5px #000000;

      }
      .sw-text {
        font-size: 1em;
        // height: 80px;
        -webkit-line-clamp: 4;

        margin: 0em auto 2em;
        width: 70%;
      }

      .sw-btn {
        margin: 0.5em auto;
        width: 70%;
      }
    }

    .footer {
      font-size: 1em !important;
    }
  }
}
