@import "./styles/loading";
@import "./styles/home-page";
@import "./styles/abt-page";
@import "./styles/blog-page";
@import "./styles/admin-page";
@import "./styles/cat-page";
@import "./styles/Blog-Section";

@import "./styles/home";
@import "./styles/about";
@import "./styles/blog";
@import "./styles/podcasts";
@import "./styles/newsletter";
@import "./styles/Poetry";
@import "./styles/contact";
@import "./styles/comments";
@import "./styles/suggestions";
@import "./styles/carddeck";


@import "./styles/footer";

@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// @font-face {
//   font-family: "apricot";
//   src: local("apricot"), url("./fonts/apricot.ttf") format("truetype");
//   font-weight: bold;
// }



html::-webkit-scrollbar {
  width: 8px;
}
html::-webkit-scrollbar-thumb {
  background-color: rgb(175, 175, 175);
  border-radius: 50px;
}
html::-webkit-scrollbar-track {
  background-color: #777;
}

body {
  font-size: 1.2rem;
  // font-family: "Alegreya", serif;
  font-family: 'Quicksand', sans-serif;
}

@media screen and (max-width: 411px) {
  html {
    font-size: 70%;
  }
}
@media screen and (max-width: 700px) {
  html {
    font-size: 90%;
  }
}
@media screen and (max-width: 480px) {
  h2 {
    font-size: 70%;
  }
  h3 {
    font-size: 70%;
  }
}
