.abt-left {
  width: 14%;
  background-color: #f8de7e;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 1em;
  .backcont {
    .back {
      margin: 0 0.3em 0 -0.5em;
    }
    &:hover {
      color: #ffa351;
    }
    transition: all 0.2s;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}



.react-reveal.abt-title {
  span {
    color: #ffa351;
  }
  font-family: "Satisfy", cursive;
  margin: .3em 0;
  font-size: 4em;
  border-bottom: 2px solid #ffa351;
  width: max-content;
  margin-left: 2em;
}

.abt-cont {
  overflow: hidden;
  display: flex;
  width: 100%;
  position: absolute;

  .abt-right {
    width: 86%;
    margin-left: 14%;
    min-height: 100vh;

    .scroll-main {
      background-color: black;
      height: 6px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      .scroll-in {
        height: 6px;
        background-color: #ffa351;
      }
    }

    .abt-desc {
      padding: 0 8em 4em;
    }
    .abt-car {
      background-color: black;
      margin: 2em 0;
      width: 50%;
      margin-left: auto;
      margin-right: auto;

      cursor: pointer;
      text-align: center;

      .slide-abt {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 0.5em 0em 0.5em;
        height: 350px;

        .car-img-1,
        .car-img-2,
        .car-img-3,
        .car-img-4,
        .car-img-5,
        .car-img-6,
        .car-img-7,
        .car-img-8,
        .car-img-9 {
          width: 100%;
          height: 100%;
          margin: 1em 0;
          background-position: center;
          background-repeat: no-repeat;
        }

        .car-img-1 {
          background-image: url("../img/abt/1.jpg");
          background-size: contain;
        }
        .car-img-2 {
          background-image: url("../img/abt/2.jpg");
          background-size: contain;
        }
        .car-img-3 {
          background-image: url("../img/abt/3.jpg");
          background-size: contain;
        }
        .car-img-4 {
          background-image: url("../img/abt/4.JPG");
          background-size: contain;
        }
        .car-img-5 {
          background-image: url("../img/abt/5.jpg");
          background-size: contain;
        }
        .car-img-6 {
          background-image: url("../img/abt/6.jpg");
          background-size: contain;
        }
        .car-img-7 {
          background-image: url("../img/abt/7.jpg");
          background-size: contain;
        }
        .car-img-8 {
          background-image: url("../img/abt/8.jpg");
          background-size: contain;
        }
        .car-img-9 {
          background-image: url("../img/abt/9.jpg");
          background-size: contain;
        }
      }
      .carousel.carousel-slider .control-arrow {
        display: none;
      }
    }
  }
}



@media screen and (max-width: 1150px) {
  .abt-left {
    display: none;
  }
  .abt-cont {
    .abt-right {
      margin-left: 0;
      width: 100%;
      .scroll-main {
        height: 5px;
        .scroll-in {
          height: 5px;
        }
      }
      .abt-car {
        width: 70%;
      }

      .abt-desc {
        padding: 0 4em 2em;
        font-size: 1.2em;
      }
    }
  }
  .react-reveal.abt-title {
    margin-left: 1em;
  }
}

@media screen and (max-width: 550px) {
  .abt-cont {
    font-size: 0.7em;

    .abt-left {
      display: none;
    }

    .react-reveal.abt-title {
      margin: 0.8em 0.8em 0.8em 1em;
      font-size: 3em;
    }
    .abt-right {
      margin-left: 0;
      width: 100%;
      .scroll-main {
        height: 2px;
        .scroll-in {
          height: 2px;
        }
      }
      .abt-desc {
        padding: 0 2em 1em;
      }
      .abt-car {
        .slide-abt {
          height: 200px !important;
        }

        .react-multiple-carousel__arrow {
          min-width: 20px;
          min-height: 20px;
        }
        .react-multiple-carousel__arrow::before {
          font-size: 10px;
        }
      }
    }
  }
}
