.poetry {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .car-insta {
    background-color: #f8de7e;
    width: 90%;
    height: 500px;
    margin: 2em 0em;

    .slide-2 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 500px;
      width: 100%;
      margin-bottom: 1em;
      .poetry-cont {
        // width: 100%;
        text-align: center;
        .vid-title {
          height: 20px;
          margin-bottom: 1.5em;
          text-align: center;
          font-size: 1.5em;
        }

        height: 600px;
        background-color: #f8de7e;
        // background-color: white;
        height: auto;
        .yt-cont {
          background-color: black;
          width: 100%;
        }

        transition: all 0.3s;
        cursor: pointer;
      }
    }
    .carousel.carousel-slider .control-arrow {
      display: block;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .poetry {
    min-height: 0;
  }
}

@media screen and (max-width: 700px) {
  .poetry {
    min-height: 0;
    .slide-2 {
      transform: scale(0.7);
    }
  }
}

@media screen and (max-width: 550px) {
  .poetry {
    min-height: 0;
    .car-insta {
      height: 300px;
      .react-multiple-carousel__arrow {
        min-width: 25px;
        min-height: 25px;
      }
      .react-multiple-carousel__arrow::before {
        font-size: 15px;
      }

    }
    .slide-2 {
      transform: scale(0.5);
        width: 200%;
      // .poetry-cont {
      //   .yt-cont {
      //     background-color: black;
      //     width: 100%;
      //   }
    // }
  }

  }
}
